import React, { FC } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container, Info } from './PatientSlotInfo';
import { Hotel } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core';

const BedInfoWrapper = styled.div<{
  size?: string;
  muted?: boolean;
}>`
  padding-left: ${props => (props.size === 'large' ? '1.5em' : '1em')};
  margin-left: ${props => (props.size === 'large' ? '1.5em' : '1em')};
  border-left: 1px solid rgba(145, 196, 255, 0.25);
  opacity: ${props => (props.muted ? '.25' : '1')};
`;

const BedContainer = styled(Container)<{
  preOpFocused?: boolean;
}>`
  margin-bottom: ${props => (props.preOpFocused ? '0rem' : '0.25em')};
  text-align: center;
`;

const useStyles = makeStyles(theme => ({
  badge: {
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1.2rem',
  },
}));

interface BedInfoProps {
  bed?: string;
  showPriority: boolean;
  priority?: number;
  size?: string;
  preOpFocused?: boolean;
}

const BedInfo: FC<BedInfoProps> = ({ bed, size, showPriority, priority, preOpFocused }) => {
  const classes = useStyles();
  return (
    <Badge
      color="primary"
      invisible={!(!!priority && showPriority)}
      badgeContent={priority}
      classes={{ badge: classes.badge }}
    >
      <BedInfoWrapper muted={!bed} size={size}>
        <BedContainer preOpFocused={preOpFocused}>
          {/* @ts-ignore */}
          <Info size={size}>{bed ? bed : '–'}</Info>
        </BedContainer>
        <Hotel
          style={{
            fontSize: size === 'large' ? '1.75rem' : '1.125rem',
          }}
        />
      </BedInfoWrapper>
    </Badge>
  );
};

BedInfo.propTypes = {
  bed: PropTypes.string,
};

export default BedInfo;
