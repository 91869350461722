import React from 'react';
import { withProps } from 'recompose';
import { Redirect, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Switch } from 'react-router-dom';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';

import { dark as darkTheme } from '../../../../theme';
import Footer from '../admin/Footer';
import SuperAdminUsers from '../../../entities/user/SuperAdminUsers';
import Hospitals from '../../../entities/hospitals/Hospitals';
import Issues from '../../../entities/issues/Issues';
import Header from '../admin/Header';
import Bracelets from '../../../entities/bracelets/Bracelets';
import ChangePassword from '../ChangePassword';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import GatewayRouting from '../../../entities/gatewayRouting/GatewayRouting';
import { withSession } from '../../../../state/Session';
import { unpackSessionObject } from '../../unpackSessionObject';
import { muiThemeDark } from '../../../../muiTheme';
import Training from '../../training/Training';
import DoctorOffices from '../../../entities/surgeonOffices/DoctorOffices';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider } from '../../../OmniSearch';
import Gateways from '../../../entities/gateways/Gateways';
import { DeviceHub, Domain, LocalHospital, NotificationImportant, People, Router, Theaters } from '@material-ui/icons';

const routes = isGroupAdmin =>
  isGroupAdmin
    ? [
        {
          to: '/hospitals',
          label: 'Hospitals',
          icon: <Domain />,
          component: Hospitals,
        },
      ]
    : [
        {
          to: '/users',
          label: 'Users',
          icon: <People />,
          component: SuperAdminUsers,
        },
        {
          to: '/hospitals',
          label: 'Hospitals',
          icon: <Domain />,
          component: Hospitals,
        },
        {
          to: '/surgeon-offices',
          label: 'Surgeon Offices',
          icon: <LocalHospital />,
          component: DoctorOffices,
        },
        {
          to: '/routing',
          label: 'Gateway Routing',
          icon: <DeviceHub />,
          component: GatewayRouting,
        },
        {
          to: '/gateways',
          label: 'Gateways',
          icon: <Router />,
          component: Gateways,
        },
        {
          to: '/issues',
          label: 'Issues',
          icon: <NotificationImportant />,
          component: Issues,
        },
        {
          to: `/training`,
          label: 'Video Tutorials',
          icon: <Theaters />,
          component: Training,
        },
      ];

const SuperAdminHeader = withProps({
  title: 'Super Admin',
})(Header);

const AdminSidebar = withProps({
  Header: SuperAdminHeader,
  Footer,
})(Sidebar);

const Homepage = ({ isGroupAdmin }) => (
  <ThemeProvider theme={darkTheme}>
    <MUIThemeProvider theme={muiThemeDark}>
      <style>
        {`body {
          background: ${muiThemeDark.palette.background.default};
        }`}
      </style>
      <CssBaseline />
      <PatientNameFormatContext.Provider value="PatientId">
        <OmniSearchProvider>
          <Page Sidebar={props => <AdminSidebar {...props} links={routes(isGroupAdmin)} />}>
            <MainContainer switchControl>
              <Switch>
                <Route exact path={'/'} render={() => <Redirect to={'/hospitals'} />} />
                {routes(isGroupAdmin).map(({ to, component }, i) => (
                  <Route key={i} path={to} component={component} />
                ))}
                <Route path={'/:organizationId/bracelets'} component={Bracelets} />
                <Route path="/change-password" component={ChangePassword} />
                <Route render={() => <Redirect to={'/hospitals'} />} />
              </Switch>
            </MainContainer>
          </Page>
        </OmniSearchProvider>
      </PatientNameFormatContext.Provider>
    </MUIThemeProvider>
  </ThemeProvider>
);

export default withSession(unpackSessionObject)(Homepage);
